.container {
	padding-bottom: 5px;
	margin-bottom: 5px;
}
.container-border {
	border-top: 1px dotted grey;
}
.value-container {
	max-width: 50%;
}
.dropdown-button {
	min-width: 100px;
}
