.cursor-not-allowed {
	cursor: not-allowed !important;
}
.item-name {
	color: #363636;
}
.item-name:hover {
	color: black;
	text-decoration: underline;
}
.disabled-link {
	pointer-events: none;
}
.apply-negative-margin {
	margin-right: -2rem;
}

@media (max-width: 950px) {
	.zeroth-hidden-column {
		display: none;
	}
}

@media (max-width: 800px) {
	.first-hidden-column {
		display: none;
	}
}

@media (max-width: 660px) {
	.second-hidden-column {
		display: none;
	}
}

@media (max-width: 550px) {
	.third-hidden-column {
		display: none;
	}
}
