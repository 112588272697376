.scrollable-dropdown {
	max-width: 70px;
	max-height: 200px;
	overflow: auto;
	text-align: center;
}

.scrollable-dropdown div:hover {
	background-color: #f5f5f5;
	color: #0a0a0a;
	cursor: pointer;
}
