.header-no-wrap {
	white-space: no-wrap;
}
.custom-mt-6 {
	margin-top: 3rem;
}

@media screen and (max-width: 768px) {
	.custom-mt-0 {
		margin-top: 0 !important;
	}
}
