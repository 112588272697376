.dropdown-menu-scrollable {
	max-height: 250px;
	overflow-y: auto;
	border: 1px solid rgb(236, 231, 231);
	border-radius: 3px;
}
.is-active-color {
	background-color: rgb(230, 230, 230) !important;
	color: black !important;
}
