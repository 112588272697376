.page-header-container {
	padding: 0 5rem;
}
.page-header-container h2 {
	font-size: 2.5rem;
}
.page-header-container span {
	font-size: 2rem;
}
.page-title-container {
	padding: 0 5rem 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media (max-width: 700px) {
	.page-header-container {
		padding: 0rem;
	}
	.website-title {
		display: flex;
		justify-content: center;
	}
	.page-header-container * {
		font-size: 2rem !important;
	}
	.version-text {
		font-size: 1rem !important;
	}
	.page-title-container {
		flex-direction: column;
	}
	.page-title {
		margin-bottom: 0.5rem;
	}
}
