.parent-menu {
	min-width: 320px;
}

.today-date {
	display: none;
}

.today-menu-item:hover .today-date {
	display: inline;
}

.max-width {
	max-width: 400px;
	white-space: normal;
	padding: 1.5rem 1rem;
}
