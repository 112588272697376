.wa-container {
	border: 1px solid #c4c4c4;
}
.wa-filter-set-container {
	border: 1px dashed #c4c4c4;
	min-height: 2rem;
}
.rule-section {
	border-left: 1px solid #c4c4c4;
}
.dragging {
	background-color: grey !important;
	color: grey !important;
}
