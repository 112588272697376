.rule-card-content {
	border: 1px solid #c0bebe;
	border-radius: 0 0 3px 3px;
	border-top: none;
}
.add-button-container {
	display: flex;
	justify-content: center;
	border-bottom: 1px solid #c0bebe;
}
.rule-name-mutate-button {
	background-color: inherit;
}
.rule-name-mutate-button :hover {
	outline: none;
}
