.page-not-found-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 3rem;
	z-index: 999;
}

.page-not-found-container div {
	font-size: 2rem;
}
