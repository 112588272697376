.home-container {
	height: 100vh;
	max-height: 100vh;
	display: flex;
	flex-direction: column;
}
.home-content {
	height: 100%;
	max-height: 100%;
	overflow: hidden;
}
.home-table-parent {
	height: 100%;
	max-height: 100%;
	overflow-y: scroll !important;
	overflow-x: hidden;
	padding: 1rem 3rem;
}

.home-table-container {
	padding-left: 1rem;
}

.table-loader-container {
	height: 100%;
}
.search-container {
	width: 60%;
	margin-right: 3rem;
}
.home-pagination-container {
	margin: 0.75rem 3rem 0.75rem 0;
}
.options-container {
	margin-left: 1.75rem;
}
@media (max-width: 750px) {
	.search-container {
		margin-right: unset;
		margin-bottom: 1rem;
		width: 100%;
		max-width: 350px;
	}
	.options-container {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-left: unset;
	}
}

@media (max-width: 550px) {
	.home-table-parent {
		padding: 1rem 0;
	}
	.home-table-container {
		padding: 0;
	}
	.home-pagination-container {
		margin: 0.5rem;
	}
}
