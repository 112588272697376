.filter-sidenav {
	display: flex;
	flex-direction: column;
	position: fixed;
	bottom: 0;
	top: 180px;
}
.filter-toggle-button-container {
	margin-top: auto;
	margin-left: auto;
}

@media (max-width: 550px) {
	.filter-sidenav {
		top: unset;
	}
	.filter-icon {
		display: none !important;
	}
}
