.dashboard-container {
	height: 100vh;
	max-height: 100vh;
	display: flex;
	flex-direction: column;
}
.users-table-container {
	max-height: 100%;
	height: 100%;
	overflow: auto;
	padding: 1rem 3rem;
}

.user-options {
	padding: 0 3rem 0.75rem;
	display: flex;
	justify-content: right;
}

.admin-pagination {
	display: flex;
	justify-content: right;
	margin-right: 3rem;
}

@media (max-width: 700px) {
	.user-options {
		justify-content: center;
	}
	.admin-pagination {
		justify-content: center;
		margin-right: unset;
	}
}

@media (max-width: 495px) {
	.users-table-container {
		padding: 0;
	}
}
