.dropdown-menu-content {
	margin-right: 100%;
	border-radius: 3px;
}
.width-100pc {
	width: 100%;
}
.disable-dropdown {
	pointer-events: none;
}
.apply-negative-margin {
	margin-right: -2rem;
}
